import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Helmet from "react-helmet";
import GlobalLayout from "components/page/GlobalLayout";
import CameraDetail from "components/cameras/detail/CameraDetailPage";

export default function R200() {
  const renderContent = data => {
    const camera = {
      model: "R200",
      type: "Dome",
      description:
        "Vandal and weather-resistant, all-purpose dome with AI analytics and Rhombus sensor compatibility.",
      webHeader: data.webImage,
      mobileHeader: data.mobileImage,
      objectPosition: "80%",
      dims: data.dims,
      pdf: "/files/specs/R200.pdf",
      specs: {
        column1: [
          {
            title: "Resolution",
            text: "5MP sensor, 2592 x 1944 resolution",
          },
          {
            title: "Aperture",
            text: "F/2.0",
          },
          {
            title: "Pan / Tilt / Zoom",
            text: "Up to 2X digital PTZ",
          },
          {
            title: "Field of View",
            text: [
              "116° Diagonal FoV",
              "104° Horizontal FoV",
              "87° Vertical FoV",
            ],
          },
          {
            title: "Focal Length",
            text: "2.8mm",
          },
          {
            title: "Dimensions",
            text: "118mm (4.64in) x 90mm (3.54in)",
          },
          {
            title: "Weight",
            text: "0.64kg (1.42lbs)",
          },
          {
            title: "Weather Resistance",
            text: "IP66",
          },
          {
            title: "Impact Resistance",
            text: "IK10",
          },
          {
            title: "Night Vision",
            text: "Infrared illuminators (100ft in low light)",
          },
        ],
        column2: [
          {
            title: "Onboard Storage Included",
            text: [
              "128GB or 20 days",
              "256GB or 40 days",
              "512GB or 90 days",
              "1TB or 180 days",
            ],
          },
          {
            title: "Ethernet",
            text: "10 / 100Mbps, MDI / MDI-X Auto Sensing",
          },
          {
            title: "Power-Over-Ethernet",
            text: "Standard 802.3af for one-cable installation",
          },
          {
            title: "Power Consumption",
            text: "Max 10.5 watts",
          },
          {
            title: "Operating Temperature",
            text: ["-20˚ to 50˚ C", "-4˚ to 122˚ F"],
          },
          {
            title: "Operating Humidity",
            text: "<95% RH",
          },
          {
            title: "In the Box",
            text: "Torx key, mounting screws, anchors",
          },
          {
            title: "Baseline Steady State",
            text: "10 - 30 Kbps per camera",
          },
          {
            title: "HD WAN Live Streaming",
            text: "530 - 720 Kbps per camera",
          },
          {
            title: "Rhombus Sensor Network Compatible",
          },
          {
            title: "CE, FCC, IC, UK, AUS, NZ, NDAA, TAA Compliant, UL Listed",
          },
          {
            title: "10-Year Warranty Included",
          },
        ],
      },
      accessoryCompatible: true,
      sensorCompatible: true,
      accessories: [
        "ACCR2CB",
        "ACCR2ARM",
        "ACCR2CM",
        "ACCR100JB2",
        "ACCR2PB",
        "ACCARMJB",
        "ACCROD40CM",
        "ACC100CAP",
        "ACCDCM1",
      ],
      exploreModels: {
        title: "Explore Other Dome Cameras",
        subtitle:
          "Find the solution that’s best suited for your needs. Compare all camera models or view other dome cameras below.",
        cameraCards: [
          {
            image: data.r120Camera,
            mobileImage: data.r120CameraMobile,
            alt: "R120 dome camera model",
            width: "189px",
            model: "R120",
            useCase: "Ideal for maximizing coverage.",
            link: "/cameras/dome-cameras/r120",
          },
          {
            image: data.r170Camera,
            mobileImage: data.r170CameraMobile,
            alt: "R170 dome camera model",
            width: "195px",
            model: "R170",
            useCase: "Ideal for discreet visibility.",
            link: "/cameras/dome-cameras/r170",
          },
          {
            image: data.r230Camera,
            mobileImage: data.r230CameraMobile,
            alt: "R230 dome camera model",
            width: "200px",
            model: "R230",
            useCase: "Ideal for flexible deployments.",
            link: "/cameras/dome-cameras/r230",
          },
          {
            image: data.r400Camera,
            mobileImage: data.r400CameraMobile,
            alt: "R400 dome camera model",
            width: "183px",
            model: "R400",
            useCase: "Ideal for use in entryways.",
            link: "/cameras/dome-cameras/r400",
          },
        ],
      },
    };
    return (
      <GlobalLayout color="transparent" dark>
        <Helmet>
          <title>Rhombus R200 Mini-Dome Security Camera</title>
          <meta
            name="description"
            content="5MP dome video surveillance camera with modern cloud management, edge-based analytics, and durable design."
          />
          <meta
            name="thumbnail"
            content="https://rhombus.com/img/r200-seo.png"
          />
        </Helmet>
        <CameraDetail camera={camera} />
      </GlobalLayout>
    );
  };
  const GET_IMAGES = graphql`
    query {
      webImage: file(
        relativePath: {
          eq: "components/cameras/detail/img/r200-header-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      mobileImage: file(
        relativePath: {
          eq: "components/cameras/detail/img/r200-header-mobile-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      dims: file(
        relativePath: {
          eq: "components/cameras/detail/img/r120-200-dims-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      r120Camera: file(
        relativePath: { eq: "components/cameras/img/explore-r120-200-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r120CameraMobile: file(
        relativePath: {
          eq: "components/cameras/img/explore-r120-200-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r170Camera: file(
        relativePath: { eq: "components/cameras/img/explore-r170-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 75, placeholder: BLURRED)
        }
      }
      r170CameraMobile: file(
        relativePath: {
          eq: "components/cameras/img/explore-r170-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 75, placeholder: BLURRED)
        }
      }
      r230Camera: file(
        relativePath: { eq: "components/cameras/img/explore-r230-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r230CameraMobile: file(
        relativePath: {
          eq: "components/cameras/img/explore-r230-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r400Camera: file(
        relativePath: { eq: "components/cameras/img/explore-r400-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 75, placeholder: BLURRED)
        }
      }
      r400CameraMobile: file(
        relativePath: {
          eq: "components/cameras/img/explore-r400-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 75, placeholder: BLURRED)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
